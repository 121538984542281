export function processString(input: string): string[] {
    // Define a mapping of accented characters to their unaccented equivalents
    const accentsMap: { [key: string]: string } = {
        'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ñ': 'ñ',
        'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', 'Ñ': 'Ñ'
    };

    // Convert the input to lowercase, remove special characters, and replace accented characters
    input = input
        .toLowerCase()
        .replace(/[áéíóúÁÉÍÓÚ]/g, match => accentsMap[match.toLowerCase()]) // Replace accented characters
        .replace(/[^\w\sñÑ]/gi, ''); // Remove special characters except ñ and Ñ

    // Define pairs to combine with an underscore
    const pairsToCombine = ["como estas", "agua saborizada", "buenos aires", "dulce de leche", "coca cola", "cuanto sale", "no hay",
        "papel higienico", "queso untable", "seven up","costa rica", 'semana que viene', 'todos los dias', 'todos los meses', 'seña personal',
        'estados unidos','buenas noches', 'buenas tardes', 'buenos dias', 'de nada', 'te amo', 'te odio', 'te quiero', 'goma de borrar',
        'lapices de colores', 'remera manga corta', 'remera manga larga', 'contencioso administrativo', 'entre rios', 'la pampa', 'la rioja',
        'rio negro', 'san luis', 'santa cruz', 'santa fe', 'santiago del estero', 'tierra del fuego', 'islas malvinas', 'san juan',
        'escoba de 15', 'un millon' ]

    // Split the input string into words
    const words = input.split(' ');

    // Create a Set for faster lookup of pairs to combine
    const pairsSet = new Set(pairsToCombine);

    // Result array to store processed words
    const result: string[] = [];

    // Iterate over the words array
    for (let i = 0; i < words.length; i++) {
        const getPair = (n) => words.slice(i, i + n).join(' ');

        if (pairsSet.has(getPair(2))) {
            result.push(getPair(2).replace(' ', '_'));
            i += 1; // Skip the next word as it is already combined
        } else if (i < words.length - 2 && pairsSet.has(getPair(3))) {
            result.push(getPair(3).replace(/ /g, '_'));
            i += 2; // Skip the next two words as they are already combined
        } else {
            result.push(words[i]);
        }
    }

    return result;
}