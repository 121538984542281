const animationsMap = {
    hola: "/animations/hola.fbx",
    aca: "/animations/aca.fbx",
    carnet: "/animations/carnet.fbx",
    gracias: "/animations/gracias.fbx",
    perdon: "/animations/perdon.fbx",
    como_estas: "/animations/como-estas.fbx",
    donde: "/animations/donde.fbx",
    nota: "/animations/nota.fbx",
    idle: "/animations/Idle.fbx",
    foto: "/animations/foto.fbx",
    pagar: "/animations/pagar.fbx",
    tarjeta: "/animations/tarjeta.fbx",
    documento: "/animations/documento.fbx",
    robo: "/animations/robo.fbx",
    sacar: "/animations/sacar.fbx",
    seguro: "/animations/seguro.fbx",
    tengo: "/animations/tengo.fbx",
    traer: "/animations/traer.fbx",
    turno: "/animations/turno.fbx",
    auto: "/animations/auto.fbx",
    conducir: "/animations/conducir.fbx",
    denuncia: "/animations/denuncia.fbx",
    examen: "/animations/examen.fbx",
    hoy: "/animations/hoy.fbx",
    medico: "/animations/medico.fbx",
    nuevo: "/animations/nuevo.fbx",
    papeles: "/animations/papeles.fbx",
    puede: "/animations/puede.fbx",
    quiero: "/animations/quiero.fbx",
    renovar: "/animations/renovar.fbx",
    asado: "/animations/asado.fbx",
    atencion: "/animations/atencion.fbx",
    atletismo: "/animations/atletismo.fbx",
    automovilismo: "/animations/automovilismo.fbx",
    ayer: "/animations/ayer.fbx",
    azucar: "/animations/azucar.fbx",
    azul: "/animations/azul.fbx",
    barrio: "/animations/barrio.fbx",
    basquet: "/animations/basquet.fbx",
    bebe: "/animations/bebe.fbx",
    blanco: "/animations/blanco.fbx",
    brasil: "/animations/brasil.fbx",
    brillante: "/animations/brillante.fbx",
    buenos_aires: "/animations/buenos-aires.fbx",
    carcel: "/animations/carcel.fbx",
    abril: "/animations/abril.fbx",
    amargo: "/animations/amargo.fbx",
    argentina: "/animations/argentina.fbx",
    arroz: "/animations/arroz.fbx",
    abuela: "/animations/abuela.fbx",
    abuelo: "/animations/abuelo.fbx",
    brillo: "/animations/brillo.fbx",
    cafe: "/animations/cafe.fbx",
    canada: "/animations/canada.fbx",
    agosto: "/animations/agosto.fbx",
    agua_saborizada: "/animations/agua-saborizada.fbx",
    agua: "/animations/agua.fbx",
    almacen: "/animations/almacen.fbx",
    amarillo: "/animations/amarillo.fbx",
    amigo: "/animations/amigo.fbx",
    anual: "/animations/anual.fbx",
    apellido: "/animations/apellido.fbx",
    años: "/animations/años.fbx",
    abogado: "/animations/abogado.fbx",
    aceite: "/animations/aceite.fbx",
    marzo: "/animations/marzo.fbx",
    mayo: "/animations/mayo.fbx",
    junio: "/animations/junio.fbx",
    julio: "/animations/julio.fbx",
    septiembre: "/animations/septiembre.fbx",
    diciembre: "/animations/diciembre.fbx",
    negro: "/animations/negro.fbx",
    oscuro: "/animations/oscuro.fbx",
    rojo: "/animations/rojo.fbx",
    rosa: "/animations/rosa.fbx",
    verde: "/animations/verde.fbx",
    violeta: "/animations/violeta.fbx",
    celeste: "/animations/celeste.fbx",
    claro: "/animations/claro.fbx",
    colores: "/animations/colores.fbx",
    gris: "/animations/gris.fbx",
    marron: "/animations/marron.fbx",
    naranja: "/animations/naranja.fbx",
    hockey: "/animations/hockey.fbx",
    handball: "/animations/handball.fbx",
    futbol: "/animations/futbol.fbx",
    golf: "/animations/golf.fbx",
    deportes: "/animations/deportes.fbx",
    ciclismo: "/animations/ciclismo.fbx",
    natacion: "/animations/natacion.fbx",
    padel: "/animations/padel.fbx",
    patin: "/animations/patin.fbx",
    rugby: "/animations/rugby.fbx",
    surf: "/animations/surf.fbx",
    tenis: "/animations/tenis.fbx",
    voley: "/animations/voley.fbx",
    cebolla: "/animations/cebolla.fbx",
    cerveza: "/animations/cerveza.fbx",
    chicle: "/animations/chicle.fbx",
    comida: "/animations/comida.fbx",
    comprar: "/animations/comprar.fbx",
    detergente: "/animations/detergente.fbx",
    dulce_de_leche: "/animations/dulce-de-leche.fbx",
    efectivo: "/animations/efectivo.fbx",
    esponja: "/animations/esponja.fbx",
    frio: "/animations/frio.fbx",
    hambre: "/animations/hambre.fbx",
    hay: "/animations/hay.fbx",
    huevo: "/animations/huevo.fbx",
    jamon: "/animations/jamon.fbx",
    leche: "/animations/leche.fbx",
    coca_cola: "/animations/coca-cola.fbx",
    cuanto_sale: "/animations/cuanto-sale.fbx",
    dia: "/animations/dia.fbx",
    edulcorante: "/animations/edulcorante.fbx",
    fideos: "/animations/fideos.fbx",
    manteca: "/animations/manteca.fbx",
    mañana: "/animations/mañana.fbx",
    mate: "/animations/mate.fbx",
    mayonesa: "/animations/mayonesa.fbx",
    mermelada: "/animations/mermelada.fbx",
    mes: "/animations/mes.fbx",
    papel_higienico: "/animations/papel-higienico.fbx",
    queso_untable: "/animations/queso-untable.fbx",
    queso: "/animations/queso.fbx",
    seven_up: "/animations/seven-up.fbx",
    yogur: "/animations/yogur.fbx",
    zanahoria: "/animations/zanahoria.fbx",
    vino: "/animations/vino.fbx",
    no_hay: "/animations/no-hay.fbx",
    pan: "/animations/pan.fbx",
    papas: "/animations/papas.fbx",
    pizza: "/animations/pizza.fbx",
    precio: "/animations/precio.fbx",
    sal: "/animations/sal.fbx",
    semana_que_viene: "/animations/semana-que-viene.fbx",
    servilleta: "/animations/servilleta.fbx",
    soda: "/animations/soda.fbx",
    tarta: "/animations/tarta.fbx",
    tomate: "/animations/tomate.fbx",
    casado: "/animations/casado.fbx",
    cumpleaños: "/animations/cumpleaños.fbx",
    cuñada: "/animations/cuñada.fbx",
    cuñado: "/animations/cuñado.fbx",
    direccion: "/animations/direccion.fbx",
    discapacidad: "/animations/discapacidad.fbx",
    edad: "/animations/edad.fbx",
    esposa: "/animations/esposa.fbx",
    esposo: "/animations/esposo.fbx",
    hijo: "/animations/hijo.fbx",
    hija: "/animations/hija.fbx",
    hijos: "/animations/hijos.fbx",
    hombre: "/animations/hombre.fbx",
    mama: "/animations/mama.fbx",
    papa: "/animations/papa.fbx",
    matrimonio: "/animations/matrimonio.fbx",
    mujer: "/animations/mujer.fbx",
    nieto: "/animations/nieto.fbx",
    harina: "/animations/harina.fbx",
    lechuga: "/animations/lechuga.fbx",
    limon: "/animations/limon.fbx",
    vinagre: "/animations/vinagre.fbx",
    familia: "/animations/familia.fbx",
    prima: "/animations/prima.fbx",
    primo: "/animations/primo.fbx",
    separado: "/animations/separado.fbx",
    sobrino: "/animations/sobrino.fbx",
    soltero: "/animations/soltero.fbx",
    sordo: "/animations/sordo.fbx",
    tia: "/animations/tia.fbx",
    tio: "/animations/tio.fbx",
    viudo: "/animations/viudo.fbx",
    aceptar: "/animations/aceptar.fbx",
    aprender: "/animations/aprender.fbx",
    armar: "/animations/armar.fbx",
    aterrizar: "/animations/aterrizar.fbx",
    ayudar: "/animations/ayudar.fbx",
    bailar: "/animations/bailar.fbx",
    bañar: "/animations/bañar.fbx",
    barrer: "/animations/barrer.fbx",
    burlar: "/animations/burlar.fbx",
    caminar: "/animations/caminar.fbx",
    capturar: "/animations/capturar.fbx",
    cocinar: "/animations/cocinar.fbx",
    contar: "/animations/contar.fbx",
    copiar: "/animations/copiar.fbx",
    dar: "/animations/dar.fbx",
    desarmar: "/animations/desarmar.fbx",
    chile: "/animations/chile.fbx",
    colombia: "/animations/colombia.fbx",
    costa_rica: "/animations/costa-rica.fbx",
    cuba: "/animations/cuba.fbx",
    ecuador: "/animations/ecuador.fbx",
    mexico: "/animations/mexico.fbx",
    pais: "/animations/pais.fbx",
    paises: "/animations/paises.fbx",
    panama: "/animations/panama.fbx",
    paraguay: "/animations/paraguay.fbx",
    peru: "/animations/peru.fbx",
    venezuela: "/animations/venezuela.fbx",
    lavandina: "/animations/lavandina.fbx",
    todos_los_dias: "/animations/todos-los-dias.fbx",
    todos_los_meses: "/animations/todos-los-meses.fbx",
    seña_personal: "/animations/seña-personal.fbx",
    decir: "/animations/decir.fbx",
    dibujar: "/animations/dibujar.fbx",
    encontrar: "/animations/encontrar.fbx",
    escribir: "/animations/escribir.fbx",
    escuchar: "/animations/escuchar.fbx",
    lavar: "/animations/lavar.fbx",
    leer: "/animations/leer.fbx",
    limpiar: "/animations/limpiar.fbx",
    llamar: "/animations/llamar.fbx",
    llorar: "/animations/llorar.fbx",
    mirar: "/animations/mirar.fbx",
    pintar: "/animations/pintar.fbx",
    tocar: "/animations/tocar.fbx",
    transparente: "/animations/transparente.fbx",
    cuanto: "/animations/cuanto.fbx",
    debo: "/animations/debo.fbx",
    sangre: "/animations/sangre.fbx",
    niño: "/animations/niño.fbx",
    niña: "/animations/niña.fbx",
    interprete: "/animations/interprete.fbx",
    oyente: "/animations/oyente.fbx",
    nombre: "/animations/nombre.fbx",
    dillo: "/animations/dillo.fbx",
    cual: "/animations/cual.fbx",
    estados_unidos: "/animations/estados-unidos.fbx",
    que:"/animations/que.fbx",
    resolucion:"/animations/resolucion.fbx",
    uno:"/animations/uno.fbx",
    dos:"/animations/dos.fbx",
    tres:"/animations/tres.fbx",
    cuatro:"/animations/cuatro.fbx",
    cinco:"/animations/cinco.fbx",
    seis:"/animations/seis.fbx",
    siete:"/animations/siete.fbx",
    ocho:"/animations/ocho.fbx",
    nueve:"/animations/nueve.fbx",
    diez:"/animations/diez.fbx",
    once:"/animations/once.fbx",
    doce:"/animations/doce.fbx",
    trece:"/animations/trece.fbx",
    catorce:"/animations/catorce.fbx",
    quince:"/animations/quince.fbx",
    dieciseis:"/animations/dieciseis.fbx",
    diecisiete:"/animations/diecisiete.fbx",
    dieciocho:"/animations/dieciocho.fbx",
    diecinueve:"/animations/diecinueve.fbx",
    veinte:"/animations/veinte.fbx",
    treinta:"/animations/treinta.fbx",
    cuarenta:"/animations/cuarenta.fbx",
    cincuenta:"/animations/cincuenta.fbx",
    sesenta:"/animations/sesenta.fbx",
    setenta:"/animations/setenta.fbx",
    ochenta:"/animations/ochenta.fbx",
    noventa:"/animations/noventa.fbx",
    cien:"/animations/cien.fbx",
    mil:"/animations/mil.fbx",
    diezmil:"/animations/diezmil.fbx",
    un_millon:"/animations/un-millon.fbx",
    millon:"/animations/millon.fbx",
    agresivo: "/animations/agresivo.fbx",
    buenas_tardes: "/animations/buenas-tardes.fbx",
    buenos_dias: "/animations/buenos-dias.fbx",
    chau: "/animations/chau.fbx",
    saludar: "/animations/saludar.fbx",
    de_nada: "/animations/de-nada.fbx",
    mentira: "/animations/mentira.fbx",
    mentiroso: "/animations/mentiroso.fbx",
    simpatico: "/animations/simpatico.fbx",
    te_amo: "/animations/te-amo.fbx",
    te_odio: "/animations/te-odio.fbx",
    te_quiero: "/animations/te-quiero.fbx",
    1:"/animations/uno.fbx",
    2:"/animations/dos.fbx",
    3:"/animations/tres.fbx",
    4:"/animations/cuatro.fbx",
    5:"/animations/cinco.fbx",
    6:"/animations/seis.fbx",
    7:"/animations/siete.fbx",
    8:"/animations/ocho.fbx",
    9:"/animations/nueve.fbx",
    10:"/animations/diez.fbx",
    11:"/animations/once.fbx",
    12:"/animations/doce.fbx",
    13:"/animations/trece.fbx",
    14:"/animations/catorce.fbx",
    15:"/animations/quince.fbx",
    16:"/animations/dieciseis.fbx",
    17:"/animations/diecisiete.fbx",
    18:"/animations/dieciocho.fbx",
    19:"/animations/diecinueve.fbx",
    20:"/animations/veinte.fbx",
    30:"/animations/treinta.fbx",
    40:"/animations/cuarenta.fbx",
    50:"/animations/cincuenta.fbx",
    60:"/animations/sesenta.fbx",
    70:"/animations/setenta.fbx",
    80:"/animations/ochenta.fbx",
    90:"/animations/noventa.fbx",
    100:"/animations/cien.fbx",
    1000:"/animations/mil.fbx",
    10000:"/animations/diezmil.fbx",
    1000000:"/animations/un-millon.fbx",
    enero:"/animations/enero.fbx",
    febrero:"/animations/febrero.fbx",
    octubre:"/animations/octubre.fbx",
    noviembre:"/animations/noviembre.fbx",
    ambo: "/animations/ambo.fbx",
    bermuda: "/animations/bermuda.fbx",
    blusa: "/animations/blusa.fbx",
    botas: "/animations/botas.fbx",
    bufanda: "/animations/bufanda.fbx",
    buzo: "/animations/buzo.fbx",
    camisa: "/animations/camisa.fbx",
    aros: "/animations/aros.fbx",
    cancan: "/animations/cancan.fbx",
    corbata: "/animations/corbata.fbx",
    desodorante: "/animations/desodorante.fbx",
    guantes: "/animations/guantes.fbx",
    medias: "/animations/medias.fbx",
    musculosa: "/animations/musculosa.fbx",
    ojotas: "/animations/ojotas.fbx",
    pantalon: "/animations/pantalon.fbx",
    pantuflas: "/animations/pantuflas.fbx",
    pañuelo: "/animations/pañuelo.fbx",
    perfume: "/animations/perfume.fbx",
    pijama: "/animations/pijama.fbx",
    polera: "/animations/polera.fbx",
    pollera: "/animations/pollera.fbx",
    pulover: "/animations/pulover.fbx",
    remera_manga_corta: "/animations/remera-manga-corta.fbx",
    remera_manga_larga: "/animations/remera-manga-larga.fbx",
    ropa_interior: "/animations/ropa-interior.fbx",
    ropa: "/animations/ropa.fbx",
    sandalias: "/animations/sandalias.fbx",
    short: "/animations/short.fbx",
    traje: "/animations/traje.fbx",
    zapatillas: "/animations/zapatillas.fbx",
    zapatos: "/animations/zapatos.fbx",
    a:"/animations/alphabet/a.fbx",
    b:"/animations/alphabet/b.fbx",
    c:"/animations/alphabet/c.fbx",
    d:"/animations/alphabet/d.fbx",
    e:"/animations/alphabet/e.fbx",
    f:"/animations/alphabet/f.fbx",
    g:"/animations/alphabet/g.fbx",
    h:"/animations/alphabet/h.fbx",
    i:"/animations/alphabet/i.fbx",
    j:"/animations/alphabet/j.fbx",
    k:"/animations/alphabet/k.fbx",
    l:"/animations/alphabet/l.fbx",
    m:"/animations/alphabet/m.fbx",
    n:"/animations/alphabet/n.fbx",
    o:"/animations/alphabet/o.fbx",
    p:"/animations/alphabet/p.fbx",
    q:"/animations/alphabet/q.fbx",
    r:"/animations/alphabet/r.fbx",
    s:"/animations/alphabet/s.fbx",
    t:"/animations/alphabet/t.fbx",
    u:"/animations/alphabet/u.fbx",
    v:"/animations/alphabet/v.fbx",
    w:"/animations/alphabet/w.fbx",
    x:"/animations/alphabet/x.fbx",
    y:"/animations/alphabet/y.fbx",
    z:"/animations/alphabet/z.fbx",
    ñ:"/animations/alphabet/ñ.fbx",
};

export default animationsMap;