import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import animationsMap from "../AnimationsMap.tsx";
import './styles.css';
import CategoryWords from "../CategoryWords.tsx";

export default function DictionaryCategory({onWordSelect}) {

    const options = Object.keys(CategoryWords);

    const handleSelectionChange = (event: React.SyntheticEvent, value: string | null) => {
        if (value) {
            console.log(value);
            onWordSelect(value);
        }
    };

    return (
        <div className='dictionary-category-container' >
            <Autocomplete
                id="word-select-demo"
                sx={{width: 250}}
                options={options}
                autoHighlight
                onChange={handleSelectionChange}
                renderOption={(props, option) => (
                    <Box
                        component="li"
                        {...props}
                    >
                        {option}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Selecciona una categoria"
                        // @ts-ignore
                        slotProps={{
                            htmlInput: {
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            },
                        }}
                    />
                )}
            />
        </div>
    );
}