import React, { useState} from 'react';
import 'regenerator-runtime/runtime';
import MenuModal from '../../components/MenuModal';
import './styles.css';
import { Canvas } from "@react-three/fiber";
import BasicSelect from "../../components/SelectClothes";
import { Experience } from "../../components/Experience.tsx";
import BottomNav from '../../components/BottomNav';
import useDeviceType from '../../utils/useDeviceType';
import ReplayButton from "../../components/ReplayButton";
import SpeedButton from "../../components/SpeedButton";
import DictionarySelect from "../../components/DictionarySelect";
import DictionaryCategory from "../../components/DictionaryCategory";
import categoryWords from "../../components/CategoryWords.tsx";

const Dictionary = () => {
    const [clothing, setClothing] = useState('hoodie');
    const [updatedText, setUpdatedText] = useState({ text: 'hola', timestamp: 0 });
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [selectedTextCategory, setSelectedTextCategory] = useState(null);
    const [speed, setSpeed] = useState(1);
    const deviceType = useDeviceType();
    const logoSize = deviceType === 'mobile' ? '140px' : '192px';

    const handleSelectionChange = (selectedClothing) => {
        setClothing(selectedClothing);
    };

    const handleTextUpdate = ({ text, timestamp }) => {
        setUpdatedText({ text, timestamp });
    };

    const handleReplayClick = () => {
        if (updatedText.text) {
            const timestamp = new Date().getTime();
            handleTextUpdate({ text: updatedText.text, timestamp });
        }
    };

    const toggleChat = () => {
        setIsChatOpen((prev) => !prev);
        if (isSelectOpen) setIsSelectOpen(false);
    };

    const closeChat = () => {
        setIsChatOpen(false);
    };

    const toggleSelect = () => {
        setIsSelectOpen((prev) => !prev);
        if (isChatOpen) setIsChatOpen(false);
    };

    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        handleReplayClick();
    };

    const handleWordSelect = (word) => {
        if (word) {
            const timestamp = new Date().getTime();
            console.log(word)
            handleTextUpdate({ text: word, timestamp });
            if(word in categoryWords){
                setSelectedTextCategory(word)
            }
        }
    };

    return (
        <div className='avatar-container'>
            <MenuModal />
            <div>
                <div className='avatar-logo'>
                    <a href="/">
                        <img
                            src="/dillo-brand.png"
                            style={{ width: logoSize }}
                            alt="Dillo Brand"
                        />
                    </a>
                </div>
                {deviceType === 'desktop' && (
                    <>
                        <DictionaryCategory onWordSelect={handleWordSelect} />
                        <DictionarySelect onWordSelect={handleWordSelect} selectedCategory={selectedTextCategory} />
                        <ReplayButton onClick={handleReplayClick}/>
                        <SpeedButton onSpeedChange={handleSpeedChange}/>
                        <BasicSelect onSelectionChange={handleSelectionChange}/>
                    </>
                )}
            </div>
            <div className={`canvas-container ${deviceType === 'mobile' ? 'mobile' : ''}`}>
                <Canvas shadows camera={{ position: [0, 0, 8], fov: 42 }}>
                    <Experience clothing={clothing} text={updatedText.text} key={`${clothing}-${updatedText.timestamp}`}  speed={speed || 0.5}  />
                </Canvas>
            </div>
            {deviceType === 'mobile' && (
                <>
                    <ReplayButton onClick={handleReplayClick} />
                    <SpeedButton onSpeedChange={handleSpeedChange} />
                    <BottomNav onChatClick={toggleChat} onClothingClick={toggleSelect} />

                    {isSelectOpen && <BasicSelect onSelectionChange={handleSelectionChange} />}
                </>
            )}
        </div>
    );
};

export default Dictionary;